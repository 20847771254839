@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Jakarta Sans', sans-serif;
}

.pricing-option-price {
  margin-top: 1rem;
}

.pricing-option-col {
  @media (min-width: 640px) {
    margin-left: 20px;
  }
}

.pricing-option {
  margin-left: 10px;
}

.pricing-option-features {
  /* No specific styles found in the document for this class */
}

.pricing-option-feature {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.pricing-option-description {
  margin: 13px 0 28px 0;
  font-weight: 300;
}

.pricing-option-container {
  background-color: #F3F5F7;
  border-radius: 12px;
  padding: 32px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.pricing-option-top {
  font-weight: 300;
}




.privacy-policy {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.privacy-policy h2 {
  color: #333;
  margin-bottom: 20px;
}

.privacy-policy h3 {
  color: #666;
  margin-bottom: 10px;
}

.privacy-policy p {
  margin-bottom: 10px;
}

.privacy-policy a {
  color: #0066cc;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
}

.privacy-policy ul {
  padding-left: 20px;
}

.privacy-policy li {
  margin-bottom: 10px;
}